import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Представительство защита в суде | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Представительство в суде - один из наиболее популярных продуктов на рынке правовых услуг. " />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/predstavitelstvo-zashchita-v-sude'
				name="Представительство, защита в суде | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Представительство, в суде - один из наиболее популярных продуктов на рынке правовых услуг. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Представительство защита в суде" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Представительство в суде - один из наиболее популярных продуктов на рынке правовых услуг. "}
			
				html={

				`
				<p class="TextText">
					<b>Представительство, защита в суде в Казани</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Даже самые законопослушные граждане, могут столкнуться с необходимостью взаимодействия с судебными органами, вследствие неправомерных действий иных лиц. Квалифицированный специалист поможет разобраться в дебрях процессуального законодательства и защитит права своего клиента.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Представительство в суде - один из наиболее популярных продуктов на рынке правовых услуг. Почему? Да все очень просто: наши граждане плохо представляют себе, что происходит в рамках судебного процесса и предпочитают доверить ведение дела профессионалу.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					К сожалению, большинство россиян не воспринимают суд, как реального защитника их прав. Часто они не обращаются к нему, даже если имеется необходимость разрешения спора независимым арбитром.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Представительство, защита в суде подразумевает возможность ведения спора не лично, а через профессионала, имеющего глубокие познания действующего материального и процессуального законодательства, а также опыт работы. Привлечение такого помощника сможет решить некоторые проблемы и наверняка заставит многих по-новому взглянуть на судебную защиту своих прав.
				</p>

				<p class="TextText">
					<b>Значение правового помощника</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Неважно, в какой роли будет выступать человек в судебном процессе, ему в любом случае будет полезно получить квалифицированную правовую помощь. Объясняется это несколькими факторами.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Во-первых, массив российского законодательства приводит в ужас профессионалов, не говоря уже об обычных граждан, туманно представляющих себе положение дел с правовым регулированием в нашей стране.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Во-вторых, сбор доказательств довольно кропотливый процесс, который требует изучение различных обстоятельств конкретного дела, изучения законодательства и правоприменительной практики. К тому же, граждане не всегда знают, где можно получить то или иное подтверждение своих доводов.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					В-третьих, специалист сможет выгодно представить доказательства клиента на судебном заседании, правильно разработать тактику выступления.
				</p>

				<p class="TextText">
					<b>Комплекс правовых услуг</b>
				</p>

				<p class="TextText">
					детальное изучение дела, последовательный разбор с клиентом каждого момента
				</p>

				<p class="TextText">
					консультации, которые могут быть представлены в письменном и в устном виде по любому вопросу, возникающему у клиента в ходе процесса
				</p>

				<p class="TextText">
					непосредственное участие адвоката в суде, подготовка судебных документов: иска, ходатайств, жалоб
				</p>

				<p class="TextText">
					контроль за исполнением решения, если оно было вынесено в пользу клиента
				</p>

				<p class="TextText">
					подача жалобы в вышестоящую инстанцию, если принятый судом вердикт противоречит интересам клиента и действующему законодательству
				</p>

				<p class="TextText">
					<b>Как не ошибиться при выборе специалиста</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Во многом именно от работы представителя будет зависеть исход дела. Практика знает случаи, когда, казалось бы, самое бесперспективное из них заканчивалось неожиданным образом или напротив сторона, которая была твердо уверенна в своей правоте и доказательствах, отказавшись от юриста или не ответственно подойдя к выбору своего правового помощника, в итоге сожалела об этом.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Компания Алмаз функционирует на ранке оказания правовых услуг не первый год и зарекомендовала себя, как надежного помощника, позволяющего решать даже самые сложные проблемы клиентов. Квалифицированные специалисты готовы продемонстрировать высокий уровень их теоретических знаний и практических навыков работы.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
